import React from "react";
import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useLocalization } from "gatsby-theme-i18n";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import InterestedItems from "../../components/InterestedItems";
import { headers } from "../../utils/navbar";
import shareholderMeetings from "../../cms/shareholderMeetingsDocs/shareholderMeetingsDocs";
import AnnualDocTabs from "../../components/AnnualDocTabs";

function ShareholderPage() {
  const { locale } = useLocalization();
  const docs = _.groupBy(shareholderMeetings[locale], "attributes.publish_year");
  const currentYears = Object.keys(docs).reverse();
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.governance.elements.allfunds_group") },
    { ..._.get(headers(), "investors.elements.share_information") },
    { ..._.get(headers(), "investors.elements.contact") },
  ];

  return (
    <NewLayout byDefault apps="investors.shareholding_meetings">
      <Container pb={8}>
        <Typography mt={3} mb={2} variant="h2">
          Shareholder Meetings
        </Typography>
        <Typography>
          Below you can find information on the AGM and other general
          meetings.
        </Typography>
        <Box mt={2}>
          <AnnualDocTabs content={docs} years={currentYears} />
        </Box>
        <InterestedItems items={interestedItems.splice(0, 3)} />
      </Container>
    </NewLayout>
  );
}

export default ShareholderPage;
