import React from "react";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { useLocalization } from "gatsby-theme-i18n";
import { transformArticleDate } from "../utils/screen";
import DocumentList from "./DocumentList";
import Tabs from "./Tabs";

function AnnualDocTabs({ content, years, title, description, children, setCurrentYear, direction = "row", isDark = false }) {
  const { locale, config } = useLocalization();
  const hrefLang = config.find((conf) => conf.code === locale)?.hrefLang;
  const [selectedYear, setSelectedYear] = React.useState(undefined);
  const currentContent = _.get(content, selectedYear, []);
  const orderByCategories = currentContent.some(({ attributes }) => attributes.document_subcategory?.data)
    ? _.groupBy(currentContent, "attributes.document_subcategory.data.attributes.title")
    : undefined;
  const subcategories = orderByCategories ? _.uniqBy(currentContent.map(({ attributes }) => attributes.document_subcategory?.data?.attributes), "title") : undefined;

  React.useEffect(() => {
    setSelectedYear(years?.includes(selectedYear) ? selectedYear : years[0]);
  }, [years]);

  return (
    <>
      <Tabs
        value={selectedYear}
        onChange={(_, value) => {
          setSelectedYear(value);
          setCurrentYear && setCurrentYear(value);
        }}
        tabs={years}
        isDark={isDark}
      />
      {children}
      <Grid mt={1} container spacing={2} alignItems="center">
        {title && (
          <Grid item xs={12} sm={4}>
            <Typography variant="h3">{title}</Typography>
            {description && <Typography mt={2}>{description}</Typography>}
          </Grid>
        )}
        <Grid item xs={12} sm={title ? 8 : 12}>
          {orderByCategories && subcategories ? (
            <>
              {subcategories.map(({ title, description, publish_date }) => (
                <StyledGrid container py={{ xs: 2, sm: 4 }}>
                  <Grid item xs={12} sm={4} sx={{ "*": { display: "block" } }}>
                    <Typography variant="h3">{title}</Typography>
                    <Typography color="neutral.main" variant="small">
                      <Trans>Published</Trans>
                      {" "}
                      {transformArticleDate(publish_date, hrefLang)}
                    </Typography>
                    <Typography my={1.5} variant="small">{description}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <DocumentList items={orderByCategories[title]} direction={direction} />
                  </Grid>
                </StyledGrid>
              ))}
            </>
          ) : (
            <DocumentList items={currentContent} direction={direction} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

const StyledGrid = styled(Grid)`
  align-items: center;
  &:not(:last-child) {
    border-bottom: ${({ theme }) => `solid 1px ${theme.palette.neutral.main}`};
  }
`;

export default AnnualDocTabs;
