import React from "react";
import {
  Box, Grid, Typography, useMediaQuery,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import DownloadPdfButton from "./DownloadPdfButton";

function DocumentList({
  items, title, description, isDark = false, isSmall = false, direction = "column", ...rest
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const _direction = isMobile ? "column" : direction;
  const color = isDark ? "white" : "primary";

  return (
    <Box {...rest}>
      {title
        && <Typography mb={2} variant={isSmall ? "h5" : "h3"} color={color}>{title}</Typography>}
      {description
        && <Typography mb={2} fontSize="16px" color={color}>{description}</Typography>}
      <Grid container spacing={1} alignItems="center">
        {!_.isEmpty(items) ? (
          items.map(({
            attributes: {
              title, file, publish_date, external_path,
            },
          }) => (
            <Grid
              item
              xs={12}
              sm={_direction === "row" ? 6 : 12}
              md={_direction === "row" ? items.length <= 2 ? 6 : 4 : 12}
              margin="0px !important"
            >
              <DownloadPdfButton
                title={title}
                path={external_path || file.data?.attributes.url}
                ext={external_path ? ".link" : file.data?.attributes.ext}
                publishDate={publish_date}
                isDark={isDark}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>
              <Trans>No documents for this year</Trans>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default DocumentList;
